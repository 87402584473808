import * as Sentry from "@sentry/react";
import * as React from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Glyphicon,
  HelpBlock,
  Row,
} from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import Footer from "../components/Footer";
import LogoLink from "../components/LogoLink";
import Api from "../lib/api";
import Cookies from "universal-cookie";

const api = new Api();

interface State {
  email: string;
  code: string;
  citizen: boolean;
  agree: string;
  errorEmail: string;
  errorAgree: string;
  errorCode: string;
  loading: boolean;
  errorText: string | null;
  errorDetail: string | null;
}

const defaultState = {
  email: "",
  code: "",
  citizen: false,
  agree: "disagree",
  errorEmail: "",
  errorAgree: "",
  errorCode: "",
  loading: false,
  errorText: null,
  errorDetail: null,
};

interface Props {
  code?: string;
}

export class StartAuthCode extends React.Component<
  RouteComponentProps & Props,
  State
> {
  state = defaultState;

  constructor(props: RouteComponentProps & Props) {
    super(props);
    if (props.code) {
      this.state.code = props.code;
    }
  }

  handleChange = (evt: any) => {
    const newState = {
      ...this.state,
      [evt.currentTarget.name]: evt.currentTarget.value,
      // Convert `email` to `errorEmail` or `code` to `errorCode`
      [`error${evt.currentTarget.name[0].toUpperCase()}${evt.currentTarget.name.slice(
        1,
      )}`]: "",
    };
    this.setState(newState);
  };
  handleToggle = (evt: any) => {
    const newState = {
      ...this.state,
      [evt.currentTarget.name]: evt.currentTarget.checked
        ? "agree"
        : "disagree",
      errorAgree: "",
    };
    this.setState(newState);
  };
  handleCitizenToggle = (evt: any) => {
    const checked = evt.currentTarget.checked;
    const newState = {
      ...this.state,
      citizen: checked,
      code: checked ? "ACPUSA" : "",
    };
    this.setState(newState);
  };
  handleSubmit = async (evt: any) => {
    evt.preventDefault();
    try {
      const agreeValid = this.validateAgree();
      const emailValid = this.validateEmail();
      const codeValid = this.validateCode();

      if (agreeValid && emailValid && codeValid) {
        const cookies = new Cookies();
        // todo - figure out how to be less-coupled here.  Some event scheme?
        Sentry.setUser({
          email: this.state.email.trim(),
          orgCode: this.state.code.trim(),
        });

        const newApp = {
          email: this.state.email,
          agree: this.state.agree,
          securityCode: this.state.code,
          referredBy: cookies.get("referred-by"),
        };
        this.setState({ loading: true });
        const response = await api.createApplicationSecurityCode(newApp);
        this.setState({ loading: false });
        if (response.data.success) {
          this.props.history.push(`/a/${response.data.message}`);
        } else {
          const nextState = {
            ...this.state,
            errorText: response.data.message,
            errorDetail: response.data.messages.join("\n"),
            loading: false,
          };
          this.setState(nextState);
        }
      }
    } catch (e) {
      const errorDetail = e instanceof Error ? e.toString() : "Error: " + e;
      this.setState({
        ...this.state,
        errorText: "Something unexpected went wrong.  Please try again later.",
        errorDetail,
        loading: false,
      });
      Sentry.captureException(e);
      // Sentry.captureException(new Error(errorDetail));
    }
  };

  validateEmail = (): boolean => {
    if (this.state.email.length === 0) {
      this.setState({
        errorEmail: "You must enter an email address.",
      });

      return false;
    } else if (!this.emailRegex.test(this.state.email.trim())) {
      this.setState({
        errorEmail: "Please enter a valid email address",
      });

      return false;
    } else {
      this.setState({
        errorEmail: "",
      });

      return true;
    }
  };
  validateCode = (): boolean => {
    if (this.state.code.length === 0) {
      this.setState({
        errorCode: "You must supply the code you were given.",
      });

      return false;
    } else {
      this.setState({
        errorCode: "",
      });

      return true;
    }
  };
  validateAgree = (): boolean => {
    if (this.state.agree !== "agree") {
      this.setState({
        errorAgree: "You must check the box to agree",
      });
      return false;
    } else {
      this.setState({
        errorAgree: "",
      });
      return true;
    }
  };

  private emailRegex = /^[^ @]+@[^ @]+\.[^ @][^ @]+$/;

  renderCodeElements() {
    return (
      <>
        {!this.state.citizen && (
          <Row>
            <FormGroup
              validationState={
                this.state.errorCode.length > 0 ? "error" : undefined
              }
            >
              <Col xs={12} sm={3} style={{ textAlign: "right" }}>
                <ControlLabel>Access Code</ControlLabel>
              </Col>

              <Col xs={12} sm={9}>
                <FormControl
                  type="password"
                  name="code"
                  value={this.state.code}
                  onChange={this.handleChange}
                />
                <HelpBlock>{this.state.errorCode}</HelpBlock>
              </Col>
            </FormGroup>
          </Row>
        )}
        <Row>
          <Col xs={12} smOffset={3} sm={9}>
            <Checkbox
              onChange={this.handleCitizenToggle}
              checked={this.state.citizen}
            >
              <strong>
                I am not affiliated with an ACP Corporate Partner, but would
                like to apply as an ACP Citizens Mentor.
              </strong>
            </Checkbox>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <div>
        <Row
          style={{
            // marginBottom: "2em",
            paddingBottom: "1em",
            background: "#fff",
          }}
        >
          <Col xs={12}>
            <h2 className="pull-left">Welcome</h2>
            <LogoLink />
          </Col>
          <Col xs={12} style={{ marginTop: "10px" }}>
            {this.props.code && (
              <>
                <p>Thank you for volunteering to be an ACP Mentor.</p>
                <p>Please enter your email address to get started.</p>
              </>
            )}
            {!this.props.code && (
              <>
                <p>
                  Thank you for volunteering to be an ACP Mentor. To help us
                  gain a better understanding of your professional background,
                  please be as thorough as possible in your application.
                </p>
                <ul className="auth-instructions">
                  <li>
                    The ideal ACP Mentor is 35+ years of age and has 6+ years of
                    professional experience.
                  </li>
                  <li>
                    Mentors will be paired with a Protégé for a yearlong
                    mentorship to provide professional guidance in the Protégé's
                    areas of interest. Pairs are expected to communicate once a
                    month.
                  </li>
                  <li>
                    Discussions will focus on topics that are important to the
                    Protégé, such as résumé writing, transitioning to a
                    corporate environment, networking or small business
                    planning.
                  </li>
                  <li>
                    Mentor applicants can expect to be contacted by ACP via
                    email within 1-3 business days of submitting an application.
                  </li>
                  <li>
                    Contact us at
                    <a href="mailto:info@acp-usa.org"> info@acp-usa.org </a> or
                    (212) 752-0700 with questions or concerns.
                  </li>
                </ul>
                <p>
                  Please enter your email address and the access code provided
                  to you.{" "}
                  <strong>
                    If you do not have an access code, please click the first
                    checkbox below to join us as an ACP Citizens Mentor.
                  </strong>
                </p>
              </>
            )}
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit}>
          <Row hidden={this.state.errorText === null}>
            <Col xs={12}>
              <Alert bsStyle={"warning"}>
                {this.state.errorText}
                <small style={{ display: "block", marginTop: "1em" }}>
                  {this.state.errorDetail}
                </small>
              </Alert>
            </Col>
          </Row>
          <Row>
            <FormGroup
              validationState={
                this.state.errorEmail.length > 0 ? "error" : undefined
              }
            >
              <Col xs={12} sm={3} style={{ textAlign: "right" }}>
                <ControlLabel>Email Address</ControlLabel>
              </Col>
              <Col xs={12} sm={9}>
                <FormControl
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <HelpBlock>{this.state.errorEmail}</HelpBlock>
              </Col>
            </FormGroup>
          </Row>

          {this.props.code ? null : this.renderCodeElements()}

          <Row>
            <Col xs={12} smOffset={3} sm={9}>
              <Checkbox
                name="agree"
                onChange={this.handleToggle}
                checked={this.state.agree === "agree"}
                validationState={
                  this.state.errorAgree.length > 0 ? "error" : undefined
                }
              >
                I accept that ACP staff will use some or all of the information
                provided in this application as a means of introduction to my
                Protégé.
                <HelpBlock>{this.state.errorAgree}</HelpBlock>
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                disabled={this.state.loading}
                className="pull-right"
                type="submit"
                bsStyle="primary"
              >
                <Glyphicon
                  glyph={this.state.loading ? "refresh" : ""}
                  className={this.state.loading ? "spin" : undefined}
                  style={{ marginRight: this.state.loading ? "10px" : "0" }}
                />
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
        <div style={{ textAlign: "center", margin: "10px 0" }}>
          <Footer />
        </div>
      </div>
    );
  }
}
